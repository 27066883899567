import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFeatureFlags } from '../store/slices/featureFlagsSlice';
import useVrackApi from '../hooks/useVrackApi';

export function useIsFeatureFlagEnabled(feature) {
  // custom hook that checks to see if the feature flag is enabled
  const featureFlags = useSelector((state) => state.featureFlags.value);
  // check the list of featureFlags to see if feature is enabled
  const found = featureFlags.find((ff) => {
    if (ff.name.toLowerCase() === feature.toLowerCase()) {
      return ff.enabled;
    }
    return false;
  });
  return found !== undefined;
}

// this hook retrieves the available feature flags from the vrack api and stores it in redux
export default function useFeatureFlags() {
  const dispatch = useDispatch();
  const jwtPayload = useSelector((state) => state.jwtPayload.value);
  const { fetchData: fetchFeatureFlags } = useVrackApi('get', '/featureflags');

  useEffect(() => {
    const getFeatureFlags = async () => {
      await fetchFeatureFlags()
        .then((response) => {
          console.debug(`successfully fetched feature flags: ${JSON.stringify(response)}`);
          dispatch(setFeatureFlags(response));
          return response;
        })
        .catch(() => {});
    };
    if (jwtPayload !== '') {
      getFeatureFlags();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, jwtPayload]);

  return null;
}
