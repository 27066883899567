import {
  Box,
  Modal,
  Fade,
  Backdrop,
  Grid,
  Card,
  CardHeader,
  Divider,
  Button,
  Stack
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import useVrackApi from '../../../../hooks/useVrackApi';
import { setOpenSnackbar } from '../../../../store/slices/snackbarSlice';
import { LoadTabGroup, tabContents } from '../../loading/LoadTabGroup';

const styles = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    p: 4
  },
  modalHeader: {
    paddingBottom: 2
  },
  modalContent: {
    padding: 2,
    width: '100%',
    minHeight: '50vh',
    maxHeight: '80vh',
    overflow: 'auto'
  }
};

// gets the kit type based on what is filled in the kitList
function getKitType(kitList) {
  if (kitList.software.length > 0) {
    return 'software';
  }
  if (kitList.content.length > 0) {
    return 'content';
  }
  return 'media';
}

// determines if we can click on the load button
function canLoad(kitList, componentsList, tabLabel) {
  if (tabLabel === 'Kits' || tabLabel === 'Build') {
    return kitList.software.length > 0 || kitList.content.length > 0 || kitList.media.length > 0;
  }
  return (
    componentsList.software.length > 0 ||
    componentsList.content.length > 0 ||
    componentsList.media.length > 0 ||
    componentsList.onemedia.length > 0
  );
}

// gets the proper kits payload to send to the vrack api with the proper fileId / srr fields
function getKitsPayload(kits) {
  const kitsPayload = [];
  kits.forEach((kit) => {
    const kitPayload = { ...kit };
    if (kit.kit_srr) {
      kitPayload.srr = kit.kit_srr;
      delete kitPayload.kit_srr;
    } else if (kit.kit_release_id) {
      kitPayload.fileId = kit.kit_release_id;
      delete kitPayload.kit_release_id;
    }
    kitsPayload.push(kitPayload);
  });
  return kitsPayload;
}

LoadModal.propTypes = {
  isOpen: PropTypes.bool,
  closeHandler: PropTypes.func,
  rackName: PropTypes.string,
  systemType: PropTypes.string
};

export default function LoadModal({ isOpen, closeHandler, rackName, systemType }) {
  const dispatch = useDispatch();
  const identity = useSelector((state) => state.identity.value);
  const [tabValue, setTabValue] = useState(0);
  const [kitList, setKitList] = useState({
    software: [],
    content: [],
    media: []
  });
  const [componentsList, setComponentsList] = useState({
    software: [],
    content: [],
    onemedia: [],
    media: []
  });

  const [loading, setLoading] = useState(false);
  const kitType = getKitType(kitList);
  const { fetchData: loadKitByType } = useVrackApi('post', `/racks/${kitType}kit`);
  const { fetchData: loadRackComponents } = useVrackApi('post', `/racks/component`);

  const loadKit = async () => {
    // validate fields
    if (kitList[kitType].length === 0) {
      openSnackbar({
        message: 'Kit List must not be empty, please add a kit to load',
        severity: 'error'
      });
      return;
    }

    const bodyData = {
      identity,
      releaseName: rackName,
      kits: getKitsPayload(kitList[kitType])
    };

    await loadKitByType({ data: bodyData })
      .then((response) => {
        console.debug(`Response from kit load API: ${JSON.stringify(response.data)}`);
        openSnackbar({ message: 'Kit Load Sent Successfully!' });
      })
      .catch((error) => {
        console.error(error);
        openSnackbar({ message: 'Failed to load kit', severity: 'error' });
      });
  };

  const loadComponents = async () => {
    const bodyData = { ...componentsList, identity, releaseName: rackName };

    await loadRackComponents({ data: bodyData })
      .then((response) => {
        console.debug(`Response from component API: ${JSON.stringify(response.data)}`);
        openSnackbar({ message: 'Components Load Sent Successfully!' });
      })
      .catch((error) => {
        console.error(error);
        openSnackbar({ message: 'Failed to load components', severity: 'error' });
      });
  };

  const openSnackbar = (payload) => dispatch(setOpenSnackbar(payload));
  const loadHandler = async () => {
    setLoading(true);
    // calls the current tab's load() function to handle the load logic for that tab
    if (tabContents[tabValue].label === 'Kits' || tabContents[tabValue].label === 'Build') {
      await loadKit();
    } else if (tabContents[tabValue].label === 'Components') {
      await loadComponents();
    }
    setLoading(false);
    closeModal();
  };

  const closeModal = () => {
    // clear component list before closing the modal
    closeHandler();
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      data-testid="load-modal"
      aria-labelledby="load-modal"
      aria-describedby="modal to show loading options for virtual racks"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={isOpen}>
        <Box sx={styles.modal}>
          <Grid container>
            <Grid item xs={12} md={1} lg={2} onClick={closeModal} />
            <Grid item xs={12} md={10} lg={8}>
              <Card>
                <CardHeader title="Loading VRack" sx={styles.modalHeader} />
                <Box sx={styles.modalContent}>
                  <LoadTabGroup
                    kitList={kitList}
                    setKitList={setKitList}
                    componentsList={componentsList}
                    setComponentsList={setComponentsList}
                    rackName={rackName}
                    systemType={systemType}
                    tabValueRef={tabValue}
                    setTabValueRef={setTabValue}
                  />
                </Box>
                <Divider />
                <Stack direction="row" spacing={1} justifyContent="flex-end" p={1}>
                  <Button onClick={closeModal} variant="outlined" color="secondary">
                    Cancel
                  </Button>
                  <LoadingButton
                    data-testid="load-button"
                    loading={loading}
                    loadingIndicator="... Loading"
                    onClick={loadHandler}
                    variant="contained"
                    color="secondary"
                    disabled={!canLoad(kitList, componentsList, tabContents[tabValue].label)}
                  >
                    {`Load ${tabContents[tabValue].label}`}
                  </LoadingButton>
                </Stack>
              </Card>
            </Grid>
            <Grid item xs={12} med={1} lg={2} onClick={closeModal} />
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
}
