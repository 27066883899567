import { useState, useEffect } from 'react';
import { Grid, TextField, Typography, Button, Stack, InputAdornment } from '@material-ui/core';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/Info';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import AirlinesRoundedIcon from '@mui/icons-material/AirlinesRounded';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import StorageIcon from '@mui/icons-material/Storage';
import useVrackApi from '../../../hooks/useVrackApi';
import LoadBuildTable from './LoadBuildTable';
import LoadBuildDetailInfo from './LoadBuildDetailIsInfo';
import SearchKitByProgram from './SearchKitByProgram';

const kitTypes = ['software', 'content', 'media'];

function formatReleaseDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US');
}

LoadBuild.propTypes = {
  kitList: PropTypes.object,
  setKitList: PropTypes.func
};

export default function LoadBuild({ kitList, setKitList }) {
  const [searchOpen, setSearchOpen] = useState(false);
  const [buildID, setBuildID] = useState('');
  const [buildDetails, setBuildDetails] = useState({
    loadables: { software: [], content: [], media: [] }
  });
  const {
    fetchData: fetchBuildDetails,
    loading,
    error
  } = useVrackApi('get', `/scmdb/builds/${buildID}`);

  const handleSelectBuild = async () => {
    await fetchBuildDetails()
      .then((response) => {
        setBuildDetails(response);
      })
      .catch((error) => {
        console.error('failed to retrieve build details', error);
      });
  };

  useEffect(() => {
    // when build changes, set its kits to the kitList
    if (buildDetails) {
      // only set the software kits right now until we support for a single kit load operation for all types
      const newKitList = { software: [], content: [], media: [] };
      kitTypes.forEach((kitType) => {
        buildDetails.loadables[kitType]?.forEach((kit) => {
          kit.system = kit.systems.toLowerCase().includes('gcs') ? 'gcs' : 'ife';
          // append the kit_srr to translate properly from build loadables to kits
          kit.kit_srr = kit.srr;
          newKitList[kitType].push(kit);
        });
      });
      setKitList(newKitList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildDetails]);

  return (
    <Grid data-testid="load-build" container mb={2} rowSpacing={4}>
      <SearchKitByProgram
        isOpen={searchOpen}
        closeHandler={() => {
          setSearchOpen(false);
        }}
        setBuildID={setBuildID}
        setBuildDetails={setBuildDetails}
      />
      <Grid item xs={12}>
        <Typography variant="subtitle1" color="gray">
          Load Build
        </Typography>
        <Stack direction="row" spacing={1} sx={{ marginTop: 2 }}>
          <TextField
            data-testid="build-id-input"
            value={buildID}
            onChange={(event) => {
              setBuildID(event.target.value);
            }}
            label="Build ID"
            variant="outlined"
            size="small"
            error={!!error}
            helperText={error ? 'Build not found' : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    data-testid="select-build"
                    disabled={loading}
                    size="medium"
                    variant="contained"
                    onClick={handleSelectBuild}
                    color={error ? 'error' : 'primary'}
                    sx={{
                      marginRight: -1.7,
                      height: 39,
                      boxShadow: 'none',
                      borderRadius: '0px 5px 5px 0px'
                    }}
                  >
                    Select
                  </Button>
                </InputAdornment>
              )
            }}
            sx={{ paddingRight: 0 }}
          />
          <Button
            startIcon={<SearchIcon />}
            variant="outlined"
            onClick={() => {
              setSearchOpen(true);
            }}
            sx={{ height: 40 }}
          >
            Search
          </Button>
        </Stack>
      </Grid>
      {buildDetails.ID && (
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="gray">
            Build Details
          </Typography>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={6} md={4}>
              <LoadBuildDetailInfo
                label="Build ID"
                value={buildDetails.ID}
                icon={<BuildCircleIcon size="small" />}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <LoadBuildDetailInfo
                label="Airline"
                value={buildDetails.airline?.name}
                icon={<AirlinesRoundedIcon size="small" />}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <LoadBuildDetailInfo
                label="Status"
                value={buildDetails.status}
                icon={<MonitorHeartIcon size="small" />}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <LoadBuildDetailInfo
                label="Systems"
                value={buildDetails.systems}
                icon={<StorageIcon size="small" />}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <LoadBuildDetailInfo
                label="Description"
                value={buildDetails.build_reason}
                icon={<InfoIcon size="small" />}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <LoadBuildDetailInfo
                label="Release Date"
                value={formatReleaseDate(buildDetails.release_date)}
                icon={<InfoIcon size="small" />}
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item xs={12}>
        <LoadBuildTable kitList={kitList} setKitList={setKitList} loading={loading} />
      </Grid>
    </Grid>
  );
}
