import { Grid, Alert } from '@material-ui/core';
import PropTypes from 'prop-types';
import AddNewComponentModal from './AddNewComponentModal';
import LoadComponentTable from './LoadComponentTable';

LoadComponent.propTypes = {
  componentsList: PropTypes.object,
  setComponentsList: PropTypes.func,
  systemType: PropTypes.string
};

export default function LoadComponent({ componentsList, setComponentsList, systemType }) {
  return (
    <Grid data-testid="load-component" container mb={2}>
      <Grid item xs={12}>
        <AddNewComponentModal
          componentsList={componentsList}
          setComponentsList={setComponentsList}
          systemType={systemType}
        />
        <LoadComponentTable componentsList={componentsList} setComponentsList={setComponentsList} />
        <Alert severity="info">
          The vrack must have the kit configuration that allows the specified components.
        </Alert>
      </Grid>
    </Grid>
  );
}
