import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';

import PropTypes from 'prop-types';

ConfirmDialog.propTypes = {
  onConfirmHandler: PropTypes.func,
  onCancelHandler: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.string,
  isOpen: PropTypes.bool
};
export default function ConfirmDialog({
  onConfirmHandler,
  onCancelHandler,
  title,
  content,
  isOpen
}) {
  const handleClose = () => {
    onCancelHandler();
  };

  const handleConfirm = () => {
    onConfirmHandler();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button data-testid="confirm-dialog-cancel" onClick={handleClose}>
          Cancel
        </Button>
        <Button data-testid="confirm-dialog-confirm" onClick={handleConfirm} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
