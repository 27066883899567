import { Typography, Stack } from '@material-ui/core';
import PropTypes from 'prop-types';

LoadBuildDetailInfo.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.any
};

export default function LoadBuildDetailInfo({ label, value, icon }) {
  return (
    <Stack direction="row" spacing={1}>
      {icon}
      <Stack spacing={0.5}>
        <Typography variant="body2" color="gray">
          {label}
        </Typography>
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          {value || 'n/a'}
        </Typography>
      </Stack>
    </Stack>
  );
}
