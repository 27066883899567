/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { DataGrid, GridToolbarQuickFilter, GridLinkOperator } from '@mui/x-data-grid';
import { Card, Link, Chip, Box } from '@material-ui/core';
import { DeleteRackButton } from '../details';
import { elapsedTimeToStr, getElapsedSeconds, fDateTimeSuffix } from '../../../utils/formatTime';

const styles = {
  table: {
    '&': { px: 1 },
    '.MuiDataGrid-cellContent': {
      overflow: 'auto',
      textOverflow: 'inherit'
    }
  }
};

const columns = [
  {
    field: 'name',
    renderCell: (params) =>
      params.value ? (
        <Link component={RouterLink} to={`/racks/details/${params.value}`} underline="none">
          {params.value}
        </Link>
      ) : (
        'n/a'
      ),
    headerName: 'Name',
    description: 'VRack Name',
    flex: 1
  },
  {
    field: 'provisioner_username',
    headerName: 'Provisioner',
    description: 'user that created the virtual rack',
    flex: 1
  },
  {
    field: 'system_type',
    headerName: 'System Type',
    description: 'the system type of the virtual rack',
    flex: 0.7,
    valueGetter: (params) => (params.row.system_type ? params.row.system_type : 'ife')
  },
  {
    field: 'created_date',
    headerName: 'Created Date',
    description: 'the date the virtual rack was created',
    flex: 0.7,
    valueGetter: (params) => fDateTimeSuffix(params.value)
  },
  {
    field: 'last_accessed',
    headerName: 'Last Accessed',
    description: 'the last time the rack was accessed',
    flex: 0.5,
    renderCell: (params) => elapsedTimeToStr(params.value),
    valueGetter: (params) => {
      return getElapsedSeconds(params.value);
    }
  },
  {
    field: 'running',
    headerName: 'Running',
    description: 'the running status of the virtual rack',
    flex: 0.5,
    type: 'string',
    valueGetter: (params) => getRackRunningStatusValue(params.row.running),
    renderCell: (params) => getRackRunningStatus(params.value)
  },
  {
    field: 'statuses',
    headerName: 'Status',
    description: 'the status of the virtual rack',
    flex: 0.5,
    valueGetter: (params) => getRackStatusValue(params.row.statuses),
    renderCell: (params) => getRackStatus(params.value)
  },
  {
    field: 'controls',
    headerName: 'Controls',
    flex: 0.3,
    align: 'right',
    disableColumnMenu: true,
    renderCell: (params) => (
      <DeleteRackButton
        type="icononly"
        variant="contained"
        rack={params.row.name}
        region={params.row.region}
      />
    )
  }
];

function convertRackStatus(status) {
  return status === 'interrupted' ? 'error' : status;
}

function getRackStatusValue(statuses) {
  if (statuses && statuses.length > 0) {
    const lastStatus = statuses[statuses.length - 1].status;
    return convertRackStatus(lastStatus);
  }
  return 'unknown';
}

function getRackStatus(status) {
  status = convertRackStatus(status);
  let color = 'secondary';
  const label = status;
  switch (status) {
    case 'error':
      color = 'error';
      break;
    case 'completed':
      color = 'success';
      break;
    case 'inprogress':
      color = 'warning';
      break;
    default:
      break;
  }
  return <Chip label={label} color={color} size="small" sx={{ minWidth: '80px' }} />;
}

function getRackRunningStatusValue(running) {
  // return 'running' for '' state. this is for racks that were created prior to the feature being added
  return running.state !== '' ? running.state : 'running';
}

function getRackRunningStatus(running) {
  let color = 'secondary';
  switch (running) {
    case 'error':
      color = 'error';
      break;
    case 'running':
      color = 'success';
      break;
    case 'configuring':
      color = 'warning';
      break;
    default:
      break;
  }
  return <Chip label={running} color={color} size="small" />;
}

function QuickSearchToolbar() {
  // function that returns a custom quick search toolbar for the datatable
  return (
    <Box
      sx={{
        mx: 1.0,
        marginTop: 2
      }}
    >
      <GridToolbarQuickFilter
        variant="outlined"
        size="small"
        quickFilterParser={(searchInput) =>
          searchInput
            .split(',')
            .map((value) => value.trim())
            .filter((value) => value !== '')
        }
      />
    </Box>
  );
}

RacksTable.propTypes = {
  rackList: PropTypes.array
};

export default function RacksTable({ rackList }) {
  const [pageSize, setPageSize] = useState(5);
  const navigate = useNavigate();
  const handleRowClick = (params) => {
    navigate(`/racks/details/${params.row.name}`);
  };

  return (
    <Card data-testid="racks-table">
      <DataGrid
        getRowId={(row) => row.name}
        rows={rackList}
        columns={columns}
        autoHeight
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        initialState={{
          filter: {
            filterModel: {
              items: [],
              quickFilterLogicOperator: GridLinkOperator.Or
            }
          },
          sorting: {
            sortModel: [{ field: 'last_accessed', sort: 'asc' }]
          }
        }}
        components={{ Toolbar: QuickSearchToolbar }}
        disableSelectionOnClick
        disableColumnSelector
        onRowClick={handleRowClick}
        sx={styles.table}
      />
    </Card>
  );
}
