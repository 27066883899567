/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { dump } from 'js-yaml';
import * as React from 'react';
import {
  Button,
  Grid,
  Box,
  Container,
  Typography,
  Step,
  StepLabel,
  Stepper
} from '@material-ui/core';
import Page from '../components/Page';
import {
  VRackInfo,
  GatewayInfo,
  LRUInfo,
  LoadInfo,
  SummaryInfo,
  Networking
} from '../components/rack/create';
import { setOpenSnackbar } from '../store/slices/snackbarSlice';
import useVrackApi from '../hooks/useVrackApi';

const steps = [
  { label: 'General', value: 'vrack_info', optional: false },
  { label: 'Build Parts List', value: 'build_parts_list', optional: false },
  { label: 'Loadables', value: 'set_load_options', optional: false },
  { label: 'Configure Network', value: 'configure_network', optional: true },
  { label: 'Configure Gateway', value: 'configure_gateway', optional: true },
  { label: 'Summary', value: 'summary', optional: false }
];

// modify the rack values if needed before sending it as payload for the Vrack Create Rack Endpoint
// TODO: remove this after we merge in decoupled changes since this will be done automatically in the backend
function getModifiedRackValues(rackValues) {
  const newRackValues = { ...rackValues };
  if (rackValues.rack.systemType === 'standalone') {
    newRackValues.rack.lrus.fs05.system_type = 'eXCs';
    newRackValues.rack.lrus.bc03.system_type = 'standalone';
  } else if (rackValues.rack.systemType === 'mo') {
    newRackValues.rack.lrus.fs05.system_type = 'eXCi';
    newRackValues.rack.lrus.bc03.system_type = 'integrated';
  }
  return newRackValues;
}

export default function CreateRack() {
  const identity = useSelector((state) => state.identity.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  // this is to persist the state of the loading tabs
  const [loadTabValue, setLoadTabValue] = useState(0);
  const { fetchData: vrackApiCreateRack } = useVrackApi('post', '/racks');
  const openSnackbar = (payload) => dispatch(setOpenSnackbar(payload));
  const [rackName, setRackName] = useState('');
  const [rack, setRack] = useState({
    environment: '',
    kits: {
      software: [],
      content: [],
      media: []
    },
    components: {
      software: [],
      content: [],
      media: [],
      onemedia: []
    },
    rack: {
      systemType: '',
      gateway: {
        size: 'small',
        diskSize: 'small',
        persistData: 'true',
        enabled: true,
        resources: {
          limits: {
            cpu: `2000m`,
            memory: `2000M`
          }
        }
      },
      lrus: {
        ah: { enabled: false, persistData: true, size: 'small', diskSize: 'small' },
        is: { enabled: false, persistData: true, size: 'small', diskSize: 'small' },
        next_is: { enabled: false, persistData: true, size: 'small', diskSize: 'small' },
        cs4: { enabled: false, persistData: true, size: 'small', diskSize: 'small' },
        next_cs4: { enabled: false, persistData: true, size: 'small', diskSize: 'small' },
        ex3_nc: { enabled: false, persistData: true, size: 'small', diskSize: 'small' },
        next_nc: { enabled: false, persistData: true, size: 'small', diskSize: 'small' },
        fs05: { enabled: false, persistData: true, size: 'small', diskSize: 'small' },
        bc03: {
          enabled: false,
          persistData: true,
          size: 'small',
          diskSize: 'small',
          system_type: 'standalone'
        },
        smartmonitor: {
          enabled: false,
          persistData: true,
          size: 'small',
          diskSize: 'small',
          config: {
            ip: '172.17.128.2',
            type: '196',
            subtype: '153',
            boardId: '66'
          }
        }
      },
      simulatedServices: {
        modem: {
          enabled: false
        },
        groundConnectivity: {
          enabled: false
        },
        flightSimulator: {
          enabled: false
        }
      },
      autoRackStop: {
        enabled: true
      },
      mikrotik: {
        enabled: false
      }
    }
  });
  const [activeStep, setActiveStep] = React.useState(0);
  const [nextButtonDisabled, setNextButtonDisabled] = React.useState(true);
  const [skipped, setSkipped] = React.useState(new Set());

  const getRackYaml = () => {
    const modifyRackValue = getModifiedRackValues(rack);
    return dump(modifyRackValue);
  };

  useEffect(() => {
    // delete any previously stored step search param
    searchParams.delete('step');
    // set the new step search param based on the currently active step
    searchParams.append('step', steps[activeStep].value);
    setSearchParams(searchParams);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [activeStep]);

  const isStepSkipped = (step) => skipped.has(step);
  const isStepOptional = (stepOrder) => steps[stepOrder].optional;

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    // Check if we've reached the end of flow
    if (activeStep === steps.length - 1) {
      const rackYaml = getRackYaml();
      createRackHandler(rackYaml);
      return false;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    return true;
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const createRackHandler = (deployValues) => {
    const bodyFormData = new FormData();
    const deployValuesBlob = new Blob([deployValues]);
    bodyFormData.append('values', deployValuesBlob, 'deploy-values.yml');
    bodyFormData.append('identity', identity);
    bodyFormData.append('releaseName', rackName);
    bodyFormData.append('provision', true);
    vrackApiCreateRack({ data: bodyFormData })
      .then((response) => {
        console.debug(`successfully created rack: ${JSON.stringify(response)}`);
        openSnackbar({
          message: `Successfully created Virtual Rack!`
        });
        setTimeout(() => {
          navigate(`/racks/details/${rackName}`, { replace: true });
        }, 3000);
        return response;
      })
      .catch((error) => {
        console.error(error.message);
        openSnackbar({
          message: `${error.response.data.message}`,
          severity: 'error'
        });
      });
  };

  return (
    <Page title="New VRack">
      <Container maxWidth={false}>
        <Grid container spacing={2} direction="row">
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h4">New VRack</Typography>
          </Grid>
          <Grid item ml={2} xs={12}>
            <Stepper activeStep={activeStep}>
              {steps.map(({ label, optional }, index) => {
                const stepProps = {};
                const labelProps = {};
                if (optional) {
                  labelProps.optional = <Typography variant="caption">Optional</Typography>;
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Grid>
          {steps[activeStep].label === 'General' && (
            <VRackInfo
              rack={rack}
              rackName={rackName}
              updateRack={setRack}
              updateRackName={setRackName}
              setNextButtonDisabled={setNextButtonDisabled}
            />
          )}
          {steps[activeStep].label === 'Configure Gateway' && (
            <GatewayInfo
              rack={rack}
              updateRack={setRack}
              setNextButtonDisabled={setNextButtonDisabled}
            />
          )}
          {steps[activeStep].label === 'Configure Network' && (
            <Networking
              rack={rack}
              updateRack={setRack}
              setNextButtonDisabled={setNextButtonDisabled}
            />
          )}
          {steps[activeStep].label === 'Build Parts List' && (
            <LRUInfo
              rack={rack}
              updateRack={setRack}
              setNextButtonDisabled={setNextButtonDisabled}
            />
          )}
          {steps[activeStep].label === 'Loadables' && (
            <LoadInfo
              rack={rack}
              updateRack={setRack}
              setNextButtonDisabled={setNextButtonDisabled}
              loadTabValue={loadTabValue}
              setLoadTabValue={setLoadTabValue}
            />
          )}
          {steps[activeStep].label === 'Summary' && <SummaryInfo rack={rack} rackName={rackName} />}
          <Grid container item xs={12}>
            <Grid item xs={6} sm={6} md={6}>
              <Box sx={{ pl: 3 }}>
                <Button
                  color="secondary"
                  variant="outlined"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Box sx={{ textAlign: 'right' }}>
                {isStepOptional(activeStep) && (
                  <Button
                    data-testid="create-skip-btn"
                    color="secondary"
                    variant="outlined"
                    onClick={handleSkip}
                    sx={{ mr: 1 }}
                  >
                    Skip
                  </Button>
                )}
                <Button
                  data-testid="create-next-btn"
                  onClick={handleNext}
                  variant="contained"
                  disabled={nextButtonDisabled}
                >
                  {activeStep === steps.length - 1 ? 'Deploy VRack' : 'Next'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
