import { useState } from 'react';

import {
  Modal,
  Fade,
  Backdrop,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Box,
  Button,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { GetUserICAO } from '../../../utils/authentication';

import AirlineSelect from '../create/AirlineSelect';
import ProgramSelect from '../create/ProgramSelect';
import BuildSelect from './BuildSelect';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  p: 4
};

SearchKitByProgram.propTypes = {
  isOpen: PropTypes.bool,
  closeHandler: PropTypes.func,
  setBuildID: PropTypes.func,
  setBuildDetails: PropTypes.func
};

export default function SearchKitByProgram({ isOpen, closeHandler, setBuildID, setBuildDetails }) {
  const userIcao = GetUserICAO();
  const [airline, setAirline] = useState(userIcao);
  const [program, setProgram] = useState('');

  const clearSearch = () => {
    // close the modal
    closeHandler();
    // reset airline/program fields for subsequent modal open
    if (airline === 'pac') {
      setAirline('');
    }
    setProgram('');
  };

  return (
    <Modal
      open={isOpen}
      sx={{ outline: 'none' }}
      aria-labelledby="search-kit-by-program-modal"
      aria-describedby="modal to search for an applicable kit"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={isOpen}>
        <Grid direction="row" sx={style} container>
          <Grid item xs={12} md={1} lg={3} onClick={closeHandler} />
          <Grid item xs={12} md={10} lg={6}>
            <Card>
              <CardHeader sx={{ padding: 2 }} title="Search Build" />
              <Divider />
              <CardContent sx={{ paddingTop: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" color="gray" sx={{ paddingLeft: 1 }}>
                      Search Builds By Airline Program
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AirlineSelect setAirline={setAirline} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ProgramSelect setProgram={setProgram} airline={airline} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" color="gray" paddingLeft={1}>
                      Builds
                    </Typography>
                    <BuildSelect
                      closeModal={clearSearch}
                      buildseries={program}
                      setBuildID={setBuildID}
                      setBuildDetails={setBuildDetails}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box sx={{ padding: 2, textAlign: 'right' }}>
                <Button
                  sx={{ marginLeft: 1 }}
                  color="secondary"
                  variant="contained"
                  endIcon={<CloseOutlinedIcon />}
                  onClick={clearSearch}
                >
                  Close
                </Button>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} med={1} lg={3} onClick={closeHandler} />
        </Grid>
      </Fade>
    </Modal>
  );
}
