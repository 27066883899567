import {
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid
} from '@material-ui/core';
import PropTypes from 'prop-types';

ComponentSystemSelect.propTypes = {
  componentTargetSystem: PropTypes.string,
  setComponentTargetSystem: PropTypes.func
};

export default function ComponentSystemSelect({ componentTargetSystem, setComponentTargetSystem }) {
  const handleChange = (event) => {
    setComponentTargetSystem(event.target.value);
  };

  return (
    <Grid data-testid="load-component-system">
      <FormControl sx={{ paddingTop: 1 }}>
        <FormLabel>Select Target System</FormLabel>
        <RadioGroup row value={componentTargetSystem} onChange={handleChange}>
          <FormControlLabel value="ife" control={<Radio size="small" />} label="IFE" />
          <FormControlLabel
            value="gcs"
            control={<Radio size="small" />}
            label="Connectivity (GCS)"
          />
          <FormControlLabel
            value="ife-gcs"
            control={<Radio size="small" />}
            label="Both IFE and GCS"
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
}
