import { FormControl, FormLabel, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import PropTypes from 'prop-types';

KitTargetSystemSelect.propTypes = {
  kitTargetSystem: PropTypes.string,
  setKitTargetSystem: PropTypes.func
};

export default function KitTargetSystemSelect({ kitTargetSystem, setKitTargetSystem }) {
  const handleChange = (event) => {
    setKitTargetSystem(event.target.value);
  };

  return (
    <FormControl sx={{ paddingLeft: 1 }}>
      <FormLabel>Select Target System</FormLabel>
      <RadioGroup row value={kitTargetSystem} onChange={handleChange}>
        <FormControlLabel value="" control={<Radio size="small" />} label="Auto" />
        <FormControlLabel value="ife" control={<Radio size="small" />} label="IFE" />
        <FormControlLabel value="gcs" control={<Radio size="small" />} label="Connectivity (GCS)" />
      </RadioGroup>
    </FormControl>
  );
}
