import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// material
import {
  IconButton,
  Button,
  Grid,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
// icons
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { boardIDValues } from './SmartMonitorOptions';
import { NewLRUModal, lruLabels } from './NewLRUModal';

export const ADD_LRU_BUTTON_TEST_ID = 'add-lru-button';
const multiOrbitLRUs = ['next_is'];

export function getAddedLRUs(lrus) {
  // returns a list of added LRUs
  return Object.keys(lrus).filter((lru) => lrus[lru].enabled);
}

// checks to see if any of the multi-orbit lrus are enabled
function isMultiOrbitLRUEnabled(lrus) {
  return multiOrbitLRUs.some((lru) => lrus[lru].enabled);
}

LRUInfo.propTypes = {
  updateRack: PropTypes.func,
  rack: PropTypes.object,
  setNextButtonDisabled: PropTypes.func
};

export function LRUInfo({ rack, updateRack, setNextButtonDisabled }) {
  const [openAddLRUModal, setOpenAddLRUModal] = useState(false);
  const [isMultiOrbitSystem, setIsMultiOrbitSystem] = useState(false);
  const { lrus } = rack.rack;
  const addedLRUS = getAddedLRUs(lrus);

  // closes the LRU Modal
  const closeNewLRUModal = () => {
    setOpenAddLRUModal(false);
  };

  const handleMultiOrbitChange = (event) => {
    setIsMultiOrbitSystem(event.target.checked);
  };

  const removeLRU = (event, lruName) => {
    event.stopPropagation();
    const rackCopy = { ...rack };
    rackCopy.rack.lrus[lruName].enabled = false;
    updateRack(rackCopy);
  };

  const getLruPrimaryDetails = (lru) => {
    if (lru === 'smartmonitor') {
      return `NEXT Smart Monitor (Type: 196, Subtype: ${lrus[lru].config.subtype})`;
    }
    return lruLabels[lru];
  };

  const getLruSecondaryDetails = (lru) => {
    // retrieves the secondary details string for an LRU.
    // The details will be the enabled units for normal LRUs and smart monitor configs if it's a smart monitor
    if (lru === 'smartmonitor') {
      return `IP: ${lrus.smartmonitor.config.ip}, Board: ${
        boardIDValues[lrus.smartmonitor.config.boardId].label
      }`;
    }
    if (lru === 'next_is') {
      return `Units: ${lrus[lru].units.join(',')}, Includes coprocessor (373_3)`;
    }
    // check if lru has units
    if (Object.keys(lrus[lru].units).length > 0) {
      return `Units: ${lrus[lru].units.join(',')}`;
    }
    return '';
  };

  useEffect(() => {
    // resets multi-orbit option if any of the Multi-Orbit capable LRUs are disabled
    if (!isMultiOrbitLRUEnabled(lrus)) {
      setIsMultiOrbitSystem(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lrus]);

  useEffect(() => {
    // validation to make sure at least one LRU is added
    if (addedLRUS.length === 0) {
      setNextButtonDisabled(true);
    } else {
      setNextButtonDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedLRUS]);

  return (
    <Grid container item spacing={2} ml={2} mt={1} mb={4} xs={12} sx={{ minHeight: '40vh' }}>
      <NewLRUModal
        rack={rack}
        updateRack={updateRack}
        isMultiOrbitSystem={isMultiOrbitSystem}
        isOpen={openAddLRUModal}
        closeHandler={closeNewLRUModal}
      />
      {/* <Grid container item xs={12}>
        <HeadendTypeSelect rack={rack} updateRack={updateRack} />
      </Grid> */}
      <Grid item xs={12} sm={4}>
        <Typography variant="h6" mb={1}>
          LRU List
        </Typography>
        <List component="nav" dense>
          {addedLRUS.length > 0 ? (
            addedLRUS.map((lru) => (
              <ListItemButton key={lru} divider>
                <ListItemText
                  primary={getLruPrimaryDetails(lru)}
                  secondary={getLruSecondaryDetails(lru)}
                  sx={{ '& p': { fontSize: '9pt' } }}
                />
                <IconButton edge="end" onClick={(event) => removeLRU(event, lru)}>
                  <HighlightOffOutlinedIcon />
                </IconButton>
              </ListItemButton>
            ))
          ) : (
            <ListItemButton divider disabled>
              <ListItemText
                primary="No LRUs Added. Please add an LRU to continue"
                sx={{ '& p': { fontSize: '9pt' } }}
              />
            </ListItemButton>
          )}
        </List>
        <Button
          data-testid={ADD_LRU_BUTTON_TEST_ID}
          variant="contained"
          onClick={() => {
            setOpenAddLRUModal(true);
          }}
          startIcon={<AddCircleOutlineRoundedIcon />}
          sx={{ mt: 1 }}
        >
          Add LRU
        </Button>
      </Grid>
      {isMultiOrbitLRUEnabled(lrus) && (
        <Grid container item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  data-testid="mo-checkbox"
                  value={isMultiOrbitSystem}
                  onChange={handleMultiOrbitChange}
                />
              }
              label="Multi-Orbit System"
            />
          </FormGroup>
        </Grid>
      )}
    </Grid>
  );
}
