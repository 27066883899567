import * as React from 'react';
import PropTypes from 'prop-types';
import * as yaml from 'js-yaml';
import { Grid, Typography, TextField, Divider, Chip } from '@material-ui/core';
import BuildPartsListSummary from './BuildPartsListSummary';
import { GetMikrotikAP } from './Mikrotik';
import { useIsFeatureFlagEnabled } from '../../../utils/FeatureFlags';
import { RegionLabels } from '../../../utils/regions';
import LoadComponentTable from '../loading/LoadComponentTable';
import LoadKitsTable from '../loading/LoadKitsTable';

function parseGatewayStartupScripts(script) {
  // converts script from json array to yaml array
  return yaml.dump(script);
}

SummaryInfo.propTypes = {
  rackName: PropTypes.string,
  rack: PropTypes.object
};

export default function SummaryInfo({ rack, rackName }) {
  const mikrotikEnabled = useIsFeatureFlagEnabled('mikrotik');
  const gcsEnabled = useIsFeatureFlagEnabled('ground_connectivity');
  return (
    <Grid container spacing={2} item ml={2} mt={1} mb={2} xs={12}>
      <Grid item mb={2} sm={12}>
        <Typography variant="h4">Summary</Typography>
        <Typography variant="caption">
          Please review your virtual rack creation details. You can select Back to edit changes for
          each section.
        </Typography>
      </Grid>
      <Grid container item spacing={2} xs={12}>
        <Grid item xs={12}>
          <Divider>
            <Chip label="Rack Info" variant="outlined" color="secondary" />
          </Divider>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="overline" mr={2}>
            Region
          </Typography>
          <Chip
            label={RegionLabels[rack.environment]}
            size="small"
            variant="outlined"
            color="primary"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="overline" mr={2}>
            Name
          </Typography>
          <Chip label={rackName} size="small" variant="outlined" color="primary" />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="overline" mr={2}>
            AutoRackStop
          </Typography>
          <Chip
            label={rack.rack.autoRackStop.enabled.toString()}
            size="small"
            variant="outlined"
            color="primary"
          />
        </Grid>
      </Grid>
      {/* lru info */}
      <BuildPartsListSummary rack={rack} />
      {/* kit info */}
      <Grid container item spacing={2} xs={12}>
        <Grid item xs={12}>
          {/* <Typography variant="h6">Rack Info</Typography> */}
          <Divider>
            <Chip label="Loadables" variant="outlined" color="secondary" />
          </Divider>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="overline" mr={2}>
            Software Kit
          </Typography>
          {rack.kits && <LoadKitsTable kitList={rack.kits} />}
        </Grid>
        <Grid container item xs={12}>
          <Typography variant="overline" mb={2} sx={{ display: 'block' }}>
            Additional Components
          </Typography>
          <LoadComponentTable componentsList={rack.components} disableControls />
        </Grid>
      </Grid>
      {/* networking */}
      <Grid container item spacing={2} xs={12}>
        <Grid item xs={12}>
          <Divider>
            <Chip label="Network Configuration" variant="outlined" color="secondary" />
          </Divider>
        </Grid>
        {rack.rack.xid !== undefined && rack.rack.xid !== 0 && (
          <Grid item xs={12}>
            <Typography variant="overline" mr={2}>
              XID
            </Typography>
            <Chip label={rack.rack.xid} size="small" variant="outlined" color="primary" />
          </Grid>
        )}
        {rack.rack.tailNumber && (
          <Grid item xs={12}>
            <Typography variant="overline" mr={2}>
              Tail Number
            </Typography>
            <Chip label={rack.rack.tailNumber} size="small" variant="outlined" color="primary" />
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="overline" mr={2}>
            Cellular Network
          </Typography>
          <Chip
            label={rack.rack.simulatedServices.modem.enabled ? 'enabled' : 'disabled'}
            size="small"
            variant="outlined"
            color="primary"
          />
        </Grid>
        {gcsEnabled && (
          <Grid item xs={12}>
            <Typography variant="overline" mr={2}>
              GCS Ku Network
            </Typography>
            <Chip
              label={
                rack.rack.simulatedServices.groundConnectivity.enabled ? 'enabled' : 'disabled'
              }
              size="small"
              variant="outlined"
              color="primary"
            />
          </Grid>
        )}
        {mikrotikEnabled && (
          <Grid container item xs={12}>
            <Grid item mb={2} xs={12}>
              <Typography variant="overline" mr={2}>
                MikroTik
              </Typography>
              <Chip
                label={rack.rack.mikrotik.enabled === true ? 'enabled' : 'disabled'}
                size="small"
                variant="outlined"
                color="primary"
              />
            </Grid>
            {rack.rack.mikrotik.uuid !== '' && (
              <Grid container item spacing={1} pl={2} xs={12}>
                <Grid item xs={12}>
                  <Typography variant="overline" mr={2}>
                    VirtAP#
                  </Typography>
                  <Chip
                    label={GetMikrotikAP(rack.rack.mikrotik.image)}
                    size="small"
                    variant="outlined"
                    color="primary"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="overline" mr={2}>
                    UUID
                  </Typography>
                  <Chip
                    label={rack.rack.mikrotik.uuid}
                    size="small"
                    variant="outlined"
                    color="primary"
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      {/* gateway info */}
      <Grid container item spacing={2} xs={12}>
        <Grid item xs={12}>
          <Divider>
            <Chip label="Gateway Configuration" variant="outlined" color="secondary" />
          </Divider>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Typography variant="overline" mb={2} sx={{ display: 'inline' }}>
              Size
            </Typography>
            <Chip
              label={rack.rack.gateway.size}
              size="small"
              variant="outlined"
              color="primary"
              sx={{ ml: 2 }}
            />
          </Grid>
          {rack.rack.gateway.size === 'custom' && (
            <Grid container item spacing={1} pl={2} mt={1} xs={12}>
              <Grid item xs={12}>
                <Typography variant="overline" mr={2}>
                  Disk Size
                </Typography>
                <Chip
                  label={rack.rack.gateway.diskSize}
                  size="small"
                  variant="outlined"
                  color="primary"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="overline" mr={2}>
                  CPU Limit
                </Typography>
                <Chip
                  label={rack.rack.gateway.resources.limits.cpu}
                  size="small"
                  variant="outlined"
                  color="primary"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="overline" mr={2}>
                  Memory Limit
                </Typography>
                <Chip
                  label={rack.rack.gateway.resources.limits.memory}
                  size="small"
                  variant="outlined"
                  color="primary"
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="overline" mr={2}>
            Flight Simulator
          </Typography>
          <Chip
            label={rack.rack.simulatedServices.flightSimulator.enabled ? 'enabled' : 'disabled'}
            size="small"
            variant="outlined"
            color="primary"
          />
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Typography variant="overline" mb={2} sx={{ display: 'block' }}>
              Advanced
            </Typography>
          </Grid>
          <Grid container item spacing={1} pl={2} xs={12}>
            <Grid item xs={12}>
              <Typography variant="overline" mr={2}>
                Persist Data
              </Typography>
              <Chip
                label={rack.rack.gateway.persistData}
                size="small"
                variant="outlined"
                color="primary"
              />
            </Grid>
            {rack.rack.gateway.initScript && (
              <Grid item xs={12}>
                <Typography variant="overline" mr={2}>
                  Init Script
                </Typography>
                <TextField
                  placeholder={parseGatewayStartupScripts(rack.rack.gateway.initScript)}
                  multiline
                  fullWidth
                  rows={5}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Grid>
            )}
            {rack.rack.gateway.bootScript && (
              <Grid item xs={12}>
                <Typography variant="overline" mr={2}>
                  Boot Script
                </Typography>
                <TextField
                  placeholder={parseGatewayStartupScripts(rack.rack.gateway.bootScript)}
                  multiline
                  fullWidth
                  rows={5}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
