import PropTypes from 'prop-types';
import uploadOutlined from '@iconify/icons-ant-design/upload-outlined';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
// material
import { Card, CardHeader, CardContent, Button, Divider, Box } from '@material-ui/core';
import LoadModal from './LoadModal';
import ComponentLoadHistory from './ComponentLoadHistory';
import KitLoadHistory from './KitLoadHistory';

LoadingInfo.propTypes = {
  rackDetails: PropTypes.object
};

export default function LoadingInfo({ rackDetails }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [loadModalOpen, setLoadModalOpen] = useState(false);
  const openModal = () => {
    searchParams.append('loading_open', true);
    setSearchParams(searchParams);
    setLoadModalOpen(true);
  };

  const closeModal = () => {
    searchParams.delete('loading_open');
    setSearchParams(searchParams);
    setLoadModalOpen(false);
  };
  return (
    <Card>
      <CardHeader title="Loading History" />
      <CardContent>
        <KitLoadHistory rackDetails={rackDetails} />
        <ComponentLoadHistory rackDetails={rackDetails} />
      </CardContent>
      <Divider />
      <Box sx={{ m: 2, textAlign: 'right' }}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<Icon icon={uploadOutlined} />}
          onClick={openModal}
        >
          Load Rack
        </Button>
      </Box>
      <LoadModal
        isOpen={loadModalOpen}
        closeHandler={closeModal}
        rackName={rackDetails.name}
        systemType={rackDetails.system_type}
      />
    </Card>
  );
}
