import {
  Typography,
  Box,
  IconButton,
  Stack,
  FormControl,
  Select,
  MenuItem
} from '@material-ui/core';
import PropTypes from 'prop-types';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import useVrackApi from '../../../hooks/useVrackApi';

const kitTypes = ['software', 'content', 'media'];

// returns the build loadable's srr id as the id of the datagrid row
function getRowId(row) {
  return row.kit_srr ? row.kit_srr : row.kit_release_id;
}

// returns the table rows from the build details
function getBuildTableRows(kitList) {
  const rows = [];
  kitTypes.forEach((type) => {
    kitList[type].forEach((kit) => {
      rows.push({ ...kit, type });
    });
  });
  return rows;
}

LoadBuildTable.propTypes = {
  kitList: PropTypes.object,
  setKitList: PropTypes.func,
  loading: PropTypes.bool
};

// eslint-disable-next-line no-unused-vars
export default function LoadBuildTable({ kitList, setKitList, loading }) {
  // maps the kit srr to its kit configs/dbs
  const [kitConfigsMap, setKitConfigsMap] = useState({});
  const { fetchData: fetchKitConfigs } = useVrackApi(
    'get',
    '/scmdb/builds/:kitID/configs?type=srr'
  );

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 2
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1
    },
    {
      field: 'system',
      headerName: 'System',
      flex: 0.7
    },
    { field: 'srr', headerName: 'SRR', flex: 1 },
    {
      field: 'kit_config',
      headerName: 'Kit Config',
      flex: 1.5,
      align: 'right',
      disableColumnMenu: true,
      renderCell: (params) =>
        params.row.type === 'software' && kitHasConfigs(params.row.srr) ? (
          <FormControl fullWidth size="small">
            <Select
              data-testid="select-config"
              disabled={isKitConfigSelectDisabled(params.row)}
              displayEmpty
              value={params.row?.kit_config?.partnumber ? params.row.kit_config.partnumber : ''}
              onChange={(e) => updateKitConfig(params.row.kit_srr, e.target.value)}
            >
              <MenuItem value="">
                <em>Select Config</em>
              </MenuItem>
              {kitConfigsMap[params.row.kit_srr]?.map((config) => (
                <MenuItem key={config.partnumber} value={config.partnumber}>
                  {config.name} ({config.partnumber} v{config.version})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null
    },
    {
      field: 'controls',
      headerName: '',
      flex: 0.5,
      align: 'right',
      disableColumnMenu: true,
      renderCell: (params) => (
        <IconButton
          edge="end"
          aria-label="remove kit"
          onClick={() => {
            removeKit(params.row.srr);
          }}
          color="error"
        >
          <RemoveCircleOutlineIcon />
        </IconButton>
      )
    }
  ];
  const rows = getBuildTableRows(kitList);

  // returns whether the kit has configs available to be selected
  const kitHasConfigs = (kitSrr) =>
    kitConfigsMap[kitSrr] ? kitConfigsMap[kitSrr].length > 0 : false;

  // determines whether the kit config selector is disabled
  const isKitConfigSelectDisabled = (rowKit) => {
    // checks whether there is already a kit config selected for the existing side, if so,
    // disable the field since there can only one per side selected
    let foundConfigSelected = false;
    kitList.software.forEach((k) => {
      // check if we find a kit that is on the same system and has a kit config selected
      if (k.kit_config && rowKit.srr !== k.srr && rowKit.system === k.system) {
        foundConfigSelected = true;
      }
    });
    return foundConfigSelected;
  };

  // updates the kit's config from the kitList state
  const updateKitConfig = (kitSrr, kitConfigPartnumber) => {
    const newKitList = { ...kitList };
    const kitConfig = kitConfigsMap[kitSrr].find(
      (config) => config.partnumber === kitConfigPartnumber
    );
    for (let i = 0; i < newKitList.software.length; i += 1) {
      if (newKitList.software[i].srr === kitSrr) {
        if (kitConfigPartnumber !== '') {
          newKitList.software[i].kit_config = kitConfig;
          break;
        } else {
          // remove the kit_config key if db was unselected
          delete newKitList.software[i].kit_config;
          break;
        }
      }
    }
    setKitList(newKitList);
  };

  // removes the kit from the kit list
  // eslint-disable-next-line no-unused-vars
  const removeKit = (kitSrr) => {
    // implememt remove from kit list
    const newKitList = { software: [], content: [], media: [] };
    kitTypes.forEach((type) => {
      kitList[type].forEach((kit) => {
        if (kit.srr !== kitSrr) {
          newKitList[type].push(kit);
        }
      });
    });
    setKitList(newKitList);
  };

  // fetches the list of kit configs using the kit srr
  const getKitConfig = async (kitSrr) => {
    await fetchKitConfigs({
      url: `/scmdb/builds/${kitSrr}/configs?type=srr`
    })
      .then((response) => {
        if (response) {
          const configs = response.reverse();
          setKitConfigsMap((prevData) => ({
            ...prevData,
            [kitSrr]: configs
          }));
        }
      })
      .catch((err) => {
        console.error(`failed to retrieve kit configs for ${kitSrr}`, err);
      });
  };

  useEffect(() => {
    // update the config list for software kits
    kitList?.software.forEach((kit) => {
      // retrieves the config for each software kit
      getKitConfig(kit.srr);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kitList]);

  return (
    <>
      <Typography variant="subtitle1" color="gray">
        Build Content
      </Typography>
      <Box data-testid="load-build-table" sx={{ height: 230, width: '100%', marginTop: 1 }}>
        <DataGrid
          disableSelectionOnClick
          disableVirtualization
          getRowId={getRowId}
          rowHeight={45}
          rows={rows}
          columns={columns}
          disableRowSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          hideFooter
          loading={loading}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No Build Selected
              </Stack>
            )
          }}
          sx={{
            [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
              outline: 'transparent'
            },
            [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]: {
              outline: 'none'
            }
          }}
        />
      </Box>
    </>
  );
}
