import * as React from 'react';
import {
  Modal,
  Fade,
  Backdrop,
  Grid,
  Card,
  CardHeader,
  Button,
  TextField,
  Alert,
  Stack,
  Divider,
  Typography,
  Checkbox
} from '@material-ui/core';

import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { LoadingButton } from '@material-ui/lab';
import useVrackApi from '../../../hooks/useVrackApi';
import { setOpenSnackbar } from '../../../store/slices/snackbarSlice';
import ConfirmDialog from '../../ConfirmDialog';
import RackTDsDropDown from './RackTDsDropDown';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  p: 4,
  outline: 'none'
};

export function capitalizeWords(val) {
  return val
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

// gets a user friendly label for the Aircraft Data value
export function getACDataLabel(acDataValue) {
  if (acDataValue) {
    return capitalizeWords(acDataValue.replaceAll('_', ' '));
  }
  return null;
}

// creates a map of Labels for the AC Data List
export function constructACDataLabelMap(fullACDataList) {
  const acDataLabelMap = {};
  fullACDataList.forEach((acData) => {
    acDataLabelMap[acData.key] = getACDataLabel(acData.value);
  });
  return acDataLabelMap;
}

RackTDsModal.propTypes = {
  isOpen: PropTypes.bool,
  closeHandler: PropTypes.func,
  rackName: PropTypes.string,
  setLoading: PropTypes.func,
  loading: PropTypes.bool
};

export default function RackTDsModal({ isOpen, closeHandler, rackName, loading, setLoading }) {
  const identity = useSelector((state) => state.identity.value);
  const [selectAll, setSelectAll] = useState(false);
  const [flightDataList, setFlightDataList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [disabledSetButton, setDisabledSetButton] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [fullFlightDataList, setFullFlightDataList] = useState([]);
  const { fetchData: vrackApiGetTDs } = useVrackApi('get', `/rack/tds`);
  const { fetchData: vrackApiSetRackTDs } = useVrackApi('post', `/racks/${rackName}/tds`);
  const { fetchData: vrackApiGetRackTds } = useVrackApi('get', `/racks/${rackName}/tds`);
  const dispatch = useDispatch();
  const openSnackbar = (payload) => dispatch(setOpenSnackbar(payload));
  const acDataLabelMap = constructACDataLabelMap(fullFlightDataList);

  const getAllTDValues = async () => {
    await vrackApiGetTDs('get', 'rack/tds')
      .then((response) => {
        setFullFlightDataList(response);
      })
      .catch((error) => {
        console.debug(error);
      });
  };

  // selects all the Aircraft Data in the list by setting the checked property to true
  const handleSelectAll = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      const newFlightDataList = [...flightDataList];
      newFlightDataList.forEach((_, i) => {
        newFlightDataList[i].checked = true;
      });
      setFlightDataList(newFlightDataList);
    }
  };

  // checks one specific Aircraft Data
  const handleCheckAircraftData = (event, i) => {
    if (!event.target.checked) {
      setSelectAll(false);
    }
    const newFlightDataList = [...flightDataList];
    newFlightDataList[i].checked = event.target.checked;
    setFlightDataList(newFlightDataList);
  };

  // filters the existing list of Aircraft Data to only include ones that are not set already in the rack
  const getFilteredAircraftDataList = () => {
    const filteredList = [];
    fullFlightDataList.forEach((acData) => {
      // the aircraft data is not in the existing rack TD list
      if (flightDataList.every((rackTD) => rackTD.key !== acData.key)) {
        const formattedACDataLabel = getACDataLabel(acData.value);
        filteredList.push({ key: acData.key, value: formattedACDataLabel });
      }
    });
    return filteredList;
  };

  const handleTDValueChange = (fdIndex, event) => {
    const updatedFlightDataList = [...flightDataList];
    updatedFlightDataList[fdIndex].value = event.target.value;
    updatedFlightDataList[fdIndex].checked = true;
    setFlightDataList(updatedFlightDataList);
  };

  const updateSelectedAircraftData = async (event, operation) => {
    setLoading(true);
    event.preventDefault();
    const selectedAircraftData = [];

    flightDataList.forEach((acData) => {
      if (acData.checked) {
        let newValue = acData.value;
        if (operation === 'clear') {
          newValue = '';
        } else if (operation === 'delete') {
          newValue = '-1';
        }
        selectedAircraftData.push({ key: acData.key, value: newValue });
      }
    });
    await vrackApiSetRackTDs({
      data: { tds: selectedAircraftData, identity, releaseName: `${rackName}` }
    })
      .then((response) => {
        openSnackbar({ message: `${operation} aircraft data values successfully` });
        console.debug(
          `${operation} aircraft data values successfully: ${JSON.stringify(response)}`
        );
      })
      .catch((error) => {
        console.error('Failed to get the rack TDs', error);
        openSnackbar({ message: `Failed to ${operation} aircraft data values`, severity: 'error' });
      });
  };

  const setSelectedAircraftData = async (event) => {
    await updateSelectedAircraftData(event, 'set');
  };

  const clearSelectedAircraftData = async (event) => {
    await updateSelectedAircraftData(event, 'clear');
  };

  const deleteSelectedAircraftData = async (event) => {
    await updateSelectedAircraftData(event, 'delete');
  };

  const getRackTDs = async (rackName) => {
    await vrackApiGetRackTds('get', `racks/${rackName}/tds`)
      .then((response) => {
        console.debug(`Response from the rack TDs endpoint: ${JSON.stringify(response)}`);
        setFlightDataList(response);
      })
      .catch((error) => {
        console.error('Failed to get the rack TDs', error);
      });
  };

  // checks if there's any unset new values before closing the modal
  const checkUnsetValuesHandler = () => {
    const unsetValueFound = flightDataList.some((fd) => fd.new);
    if (unsetValueFound) {
      setConfirmDialogOpen(true);
    } else {
      closeHandler();
    }
  };

  const cancelConfirmDialogHandler = () => {
    setConfirmDialogOpen(false);
  };

  const confirmDialogHandler = () => {
    setConfirmDialogOpen(false);
    closeHandler();
  };

  useEffect(() => {
    if (isOpen === true) {
      getRackTDs(rackName);
      getAllTDValues();
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isOpen]);

  // look for changes with flightDataList, loop through all the values and if any have a checked === true then setDisabledSetButton = false
  useEffect(() => {
    setDisabledSetButton(true);
    flightDataList.forEach((acData) => {
      if (acData.checked) {
        setDisabledSetButton(false);
      }
    });
  }, [flightDataList]);

  useEffect(() => {
    // when finished loading, requery the rack TDs to update
    if (!loading) {
      getRackTDs(rackName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <>
      <ConfirmDialog
        title="Discard Unset Aircraft Data?"
        content="There are unset aircraft data found, do you want to discard the changes?"
        onCancelHandler={cancelConfirmDialogHandler}
        onConfirmHandler={confirmDialogHandler}
        isOpen={confirmDialogOpen}
      />
      <Modal
        open={isOpen}
        onClose={checkUnsetValuesHandler}
        aria-labelledby="Flight-Data"
        aria-describedby="modal to show the aircraft data for virtual racks"
        BackdropComponent={Backdrop}
        data-testid="tds-modal"
      >
        <Fade in={isOpen}>
          <Grid container item direction="row" sx={style}>
            <Grid item xs={12} md={1} lg={3} onClick={checkUnsetValuesHandler} />
            <Grid item xs={12} md={10} lg={6}>
              <Card>
                <CardHeader title="Aircraft Data" />
                <Stack margin={2} rowGap={2}>
                  <Alert severity="warning">
                    Aircraft Data parameters will not persist if the LRU is rebooted. If a reboot
                    occurs, you need to reset the values.
                  </Alert>
                  <RackTDsDropDown
                    flightDataList={flightDataList}
                    setFlightDataList={setFlightDataList}
                    aircraftDataList={getFilteredAircraftDataList()}
                  />
                  <Stack direction="row" alignItems="center">
                    <Checkbox
                      checked={selectAll}
                      onChange={handleSelectAll}
                      inputProps={{
                        'data-testid': 'select-all-checkbox'
                      }}
                    />
                    <Typography p={0} m={0}>
                      Select All
                    </Typography>
                  </Stack>
                  <Stack
                    spacing={3}
                    paddingTop={1}
                    sx={{ maxHeight: '40vh', overflow: 'auto', marginTop: 0 }}
                  >
                    {flightDataList.map((fd, i) => (
                      <Stack direction="row" key={fd.key}>
                        <Checkbox
                          checked={Boolean(fd.checked)}
                          onChange={(e) => handleCheckAircraftData(e, i)}
                          inputProps={{
                            'data-testid': 'fd-checkbox'
                          }}
                        />
                        <TextField
                          size="small"
                          label={acDataLabelMap[fd.key]}
                          value={fd.value}
                          onChange={(e) => handleTDValueChange(i, e)}
                          fullWidth
                          variant="outlined"
                          focused={fd.new}
                          placeholder="Undefined"
                          inputProps={{
                            'data-testid': 'td-value-field'
                          }}
                        />
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
                <Divider />
                <Grid container spacing={2} justifyContent="space-between" sx={{ p: 2 }}>
                  <Grid item xs={12} sm={2}>
                    <Button
                      onClick={checkUnsetValuesHandler}
                      size="small"
                      color="secondary"
                      startIcon={<CloseOutlinedIcon />}
                      variant="outlined"
                      fullWidth
                      data-testid="close-button"
                    >
                      Close
                    </Button>
                  </Grid>
                  <Grid container item spacing={1} xs={12} sm={8} md={6}>
                    <Grid item xs={12} sm={4}>
                      <LoadingButton
                        onClick={setSelectedAircraftData}
                        size="small"
                        loading={loading}
                        variant="contained"
                        disabled={disabledSetButton}
                        fullWidth
                        data-testid="set-values-button"
                      >
                        <span>Set Values</span>
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <LoadingButton
                        onClick={clearSelectedAircraftData}
                        size="small"
                        loading={loading}
                        variant="outlined"
                        disabled={disabledSetButton}
                        fullWidth
                        data-testid="clear-values-button"
                      >
                        <span>Clear Values</span>
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <LoadingButton
                        data-testid="delete-values-button"
                        onClick={deleteSelectedAircraftData}
                        size="small"
                        loading={loading}
                        variant="outlined"
                        color="error"
                        disabled={disabledSetButton}
                        fullWidth
                      >
                        <span>Delete Field</span>
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
              <Grid item xs={12} med={1} lg={3} onClick={checkUnsetValuesHandler} />
            </Grid>
          </Grid>
        </Fade>
      </Modal>
    </>
  );
}
