import { Grid, FormGroup, FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import InfoToolTip from '../../common/InfoToolTip';
import { SmartMonitorOptions } from './SmartMonitorOptions';

export const LRU_OPTIONS_TITLE = 'LRU Options';

LRUOptions.propTypes = {
  currentLRU: PropTypes.object,
  updateCurrentLRU: PropTypes.func
};

export function LRUOptions({ currentLRU, updateCurrentLRU }) {
  const setLRUUnits = (event) => {
    // update units for lru
    const s = { ...currentLRU };
    s.units[event.target.name] = event.target.checked;
    updateCurrentLRU(s);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">{LRU_OPTIONS_TITLE}</Typography>
      </Grid>
      <Grid container item ml={2} xs={12}>
        {Object.keys(currentLRU.units).length > 0 ? (
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              Units
              <InfoToolTip title="Specifying more unit numbers will deploy more LRUs of the same type." />
            </Typography>
            <FormGroup row>
              {Object.keys(currentLRU.units).map((k) => (
                <FormControlLabel
                  key={k}
                  checked={currentLRU.units[k]}
                  control={<Checkbox name={k.toString()} />}
                  onChange={setLRUUnits}
                  label={k}
                />
              ))}
            </FormGroup>
          </Grid>
        ) : null}
        {currentLRU.value === 'smartmonitor' ? (
          <SmartMonitorOptions lru={currentLRU} updateLRU={updateCurrentLRU} />
        ) : null}
      </Grid>
    </>
  );
}
