import { Typography, Box, IconButton, Stack } from '@material-ui/core';
import PropTypes from 'prop-types';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { DataGrid, gridClasses } from '@mui/x-data-grid';

const kitTypes = ['software', 'content', 'media'];

// gets the row id of the table
function getRowId(row) {
  return row.kit_srr ? row.kit_srr : row.kit_release_id;
}

LoadKitsTable.propTypes = {
  kitList: PropTypes.object,
  setKitList: PropTypes.func
};

export default function LoadKitsTable({ kitList, setKitList }) {
  const columns = [
    {
      field: 'id',
      headerName: 'Identifier',
      flex: 1,
      valueGetter: (params) => (params.row.kit_srr ? params.row.kit_srr : params.row.kit_release_id)
    },
    {
      field: 'type',
      headerName: 'Type',
      editable: false,
      flex: 1
    },
    {
      field: 'system',
      headerName: 'System',
      flex: 1,
      valueGetter: (params) => (params.value ? `${params.value}` : 'unspecified')
    },
    {
      field: 'config',
      headerName: 'Kit Config',
      description: 'the kit configuration',
      editable: false,
      flex: 2,
      valueGetter: (params) =>
        params.value
          ? `${params.value.name} (${params.value.partnumber} v${params.value.version})`
          : 'n/a'
    },
    {
      field: 'controls',
      headerName: '',
      editable: false,
      flex: 0.5,
      align: 'right',
      disableColumnMenu: true,
      renderCell: (params) =>
        setKitList && (
          <IconButton
            aria-label="remove kit"
            edge="end"
            onClick={() => {
              removeKit(params.row);
            }}
            color="error"
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
        )
    }
  ];

  // removes the kit from the kit list
  const removeKit = (kitRow) => {
    const newKitList = { ...kitList };
    const kits = newKitList[kitRow.type];
    // look for the right kit to remove by the id
    kits.forEach((kit, i) => {
      if (kitRow.id === kit.kit_srr || kitRow.id === kit.kit_release_id) {
        kits.splice(i, 1);
      }
    });
    newKitList[kitRow.type] = kits;
    setKitList(newKitList);
  };

  const getKitRows = () => {
    const rows = [];
    kitTypes.forEach((kitType) => {
      kitList[kitType].forEach((kit) => {
        const kitRow = {
          type: kitType,
          ...kit
        };
        rows.push(kitRow);
      });
    });
    return rows;
  };

  return (
    <>
      <Typography variant="subtitle1" color="gray" sx={{ paddingLeft: 1, marginTop: 2 }}>
        Kits
      </Typography>
      <Box data-testid="load-kits-table" sx={{ height: 200, width: '100%', marginTop: 1 }}>
        <DataGrid
          disableVirtualization
          getRowId={getRowId}
          rowHeight={45}
          rows={getKitRows()}
          columns={columns}
          disableRowSelectionOnClick
          disableColumnFilter
          hideFooter
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No Kits Added
              </Stack>
            )
          }}
          sx={{
            [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
              outline: 'transparent'
            },
            [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]: {
              outline: 'none'
            }
          }}
        />
      </Box>
    </>
  );
}
