import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  InputLabel,
  Button,
  Tooltip,
  Grid
} from '@material-ui/core';
import PlaceIcon from '@mui/icons-material/Place';
import { useIsFeatureFlagEnabled } from '../../../utils/FeatureFlags';
import { RegionLabels, RegionCoordinates } from '../../../utils/regions';

const regionLocalstorageKey = 'vrackRegion';

const styles = {
  textField: { minWidth: 400 },
  locationButton: {
    height: 55
  }
};

// calculates the distance (km) between 2 locations based on their lat & long
function calculateDistance(lat1, lon1, lat2, lon2) {
  const toRadians = (degrees) => degrees * (Math.PI / 180);
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in kilometers
}

// returns the default region for the user based on what was stored in localstorage from previous sessions
function getDefaultRegion(rackRegion) {
  if (rackRegion !== '') {
    // return rack region if it was set already
    return rackRegion;
  }
  // try to get the previous region set in localstorage
  let defaultRegion = localStorage.getItem(regionLocalstorageKey);
  if (defaultRegion === '' || !defaultRegion) {
    // if region wasn't saved before in localstorage, use fallback region
    defaultRegion = 'us-west-2';
  }
  return defaultRegion;
}

RegionSelect.propTypes = {
  rack: PropTypes.object,
  updateRack: PropTypes.func,
  handleRegionValidationChange: PropTypes.func
};

export default function RegionSelect({ rack, updateRack, handleRegionValidationChange }) {
  const isMumbaiEnabled = useIsFeatureFlagEnabled('mumbai_region');
  const [region, setRegion] = useState(getDefaultRegion(rack?.environment));
  const [locationLoading, setLocationLoading] = useState(false);

  const updateRackRegion = (region) => {
    updateRack((prevstate) => ({
      ...prevstate,
      environment: region
    }));
  };

  const handleRegionChange = (newRegion) => {
    // update localstorage, state, & rack object
    localStorage.setItem(regionLocalstorageKey, newRegion);
    setRegion(newRegion);
  };

  // finds the closest region compared to the user's position
  const determineCloserLocation = (position) => {
    const userLat = position.coords.latitude;
    const userLon = position.coords.longitude;

    let closestRegion = Object.keys(RegionCoordinates)[0];
    let closestRegionDistance = 999999999;
    Object.keys(RegionCoordinates).forEach((r) => {
      if (r === 'ap-south-1' && !isMumbaiEnabled) {
        return;
      }

      const regionCoord = RegionCoordinates[r];
      const regionDist = calculateDistance(userLat, userLon, regionCoord.lat, regionCoord.long);
      console.debug(r, 'region distance from user: ', regionDist);
      if (closestRegionDistance > regionDist) {
        closestRegion = r;
        closestRegionDistance = regionDist;
      }
    });
    handleRegionChange(closestRegion);
    console.debug('the closest region selected is ', closestRegion);
    setLocationLoading(false);
  };

  const selectClosestRegion = () => {
    setLocationLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(determineCloserLocation, (error) => {
        console.error('Unable to select the closest region', error);
        setLocationLoading(false);
      });
    }
  };

  useEffect(() => {
    // updates the rack region when the region changes
    if (region !== '') {
      updateRackRegion(region);
      handleRegionValidationChange(true);
    }
    // eslint-disable-next-line
  }, [region]);

  return (
    <Grid container item columnSpacing={1}>
      <Grid item xs={8} sm={6} lg={4}>
        <FormControl required fullWidth>
          <InputLabel id="region-label">Select a Region</InputLabel>
          <Select
            labelId="region"
            id="region-selector"
            label="Select a Region *"
            value={region}
            onChange={(e) => handleRegionChange(e.target.value)}
          >
            {Object.keys(RegionLabels).map((region) => (
              <MenuItem
                key={region}
                value={region}
                disabled={region === 'ap-south-1' && !isMumbaiEnabled}
              >
                {RegionLabels[region]}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            For the best user experience, select a region geographically closest to the user
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <Tooltip title="Chooses the nearest geographic location to you">
          <Button
            data-testid="detect-region-icon"
            onClick={selectClosestRegion}
            sx={styles.locationButton}
            variant="outlined"
            color="primary"
            disabled={locationLoading}
            startIcon={<PlaceIcon fontSize="medium" />}
          >
            Auto Detect
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  );
}
