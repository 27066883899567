import { useState } from 'react';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Button
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { SoftwareKitSelect, SoftwareKitConfigSelect } from '.';
import LoadKitsTable from './LoadKitsTable';
import KitTargetSystemSelect from './KitTargetSystemSelect';

LoadKits.propTypes = {
  kitList: PropTypes.object,
  setKitList: PropTypes.func,
  systemType: PropTypes.string
};

export default function LoadKits({ kitList, setKitList, systemType }) {
  const [kitID, setKitID] = useState('');
  const [kitIDType, setKitIDType] = useState('kit_release_id');
  const [kitType, setKitType] = useState('software');
  const [kitConfig, setKitConfig] = useState(null);
  const [kitTargetSystem, setKitTargetSystem] = useState('');

  const isAddKitDisabled = kitID === '';

  const setKitTypeChange = (event) => {
    setKitType(event.target.value);
    // clear kit info when type is changed
    clearKit();
    clearKitList();
  };

  // clears the information set on the current kit
  const clearKit = () => {
    setKitID('');
    setKitIDType('kit_release_id');
    setKitConfig(null);
    setKitTargetSystem(null);
  };

  // clears the kit list table
  const clearKitList = () => {
    setKitList({
      software: [],
      content: [],
      media: []
    });
  };

  // the handler that adds a kit to the kit list
  const handleAddKit = () => {
    const newKitList = { ...kitList };
    const newKit = {};
    newKit[kitIDType] = parseInt(kitID, 10);
    // kit had a target system type if decoupled
    if (kitTargetSystem && systemType === 'decoupled') {
      newKit.system = kitTargetSystem;
    } else if (systemType !== 'decoupled') {
      newKit.system = systemType === 'standalone' ? 'gcs' : 'ife';
    }
    // software kit had a kit config specified
    if (kitConfig && kitType === 'software') {
      newKit.config = kitConfig;
    }
    // add the kit to the list
    newKitList[kitType].push(newKit);
    setKitList(newKitList);
    clearKit();
  };

  return (
    <Grid data-testid="load-kits-section" container spacing={3} paddingTop={1}>
      <Grid item xs={12}>
        <FormControl sx={{ paddingLeft: 1 }}>
          <FormLabel>Kit Type</FormLabel>
          <RadioGroup row value={kitType} onChange={setKitTypeChange}>
            <FormControlLabel value="software" control={<Radio size="small" />} label="Software" />
            <FormControlLabel value="content" control={<Radio size="small" />} label="Content" />
            <FormControlLabel value="media" control={<Radio size="small" />} label="Media" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SoftwareKitSelect
          kitID={kitID}
          setKitID={setKitID}
          kitIDType={kitIDType}
          setKitIDType={setKitIDType}
        />
      </Grid>
      {kitType === 'software' && (
        <Grid item xs={12} sm={6}>
          <SoftwareKitConfigSelect
            kitID={kitID}
            kitIDType={kitIDType}
            kitConfig={kitConfig}
            setKitConfig={setKitConfig}
          />
        </Grid>
      )}
      {systemType === 'decoupled' && (
        <Grid item xs={12}>
          <KitTargetSystemSelect
            kitTargetSystem={kitTargetSystem}
            setKitTargetSystem={setKitTargetSystem}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddKit}
          disabled={isAddKitDisabled}
        >
          Add Kit
        </Button>
      </Grid>
      <Grid item xs={12}>
        <LoadKitsTable kitList={kitList} setKitList={setKitList} />
      </Grid>
    </Grid>
  );
}
