import { Box, Tabs, Tab } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { setOpenSnackbar } from '../../../store/slices/snackbarSlice';
import LoadComponent from './LoadComponent';
import LoadBuild from './LoadBuild';
import LoadKits from './LoadKits';

export const tabContents = [{ label: 'Build' }, { label: 'Kits' }, { label: 'Components' }];

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

LoadTabGroup.propTypes = {
  rackName: PropTypes.string,
  systemType: PropTypes.string,
  kitList: PropTypes.object,
  setKitList: PropTypes.func,
  componentsList: PropTypes.object,
  setComponentsList: PropTypes.func,
  tabValueRef: PropTypes.number,
  setTabValueRef: PropTypes.func
};

export function LoadTabGroup({
  rackName,
  systemType,
  kitList,
  setKitList,
  componentsList,
  setComponentsList,
  tabValueRef,
  setTabValueRef
}) {
  const dispatch = useDispatch();
  const identity = useSelector((state) => state.identity.value);

  const openSnackbar = (payload) => dispatch(setOpenSnackbar(payload));
  const handleTabChange = (event, newValue) => {
    setTabValueRef(newValue);
  };

  return (
    <Box>
      <Box data-testid="load-tab-group" sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          textColor="primary"
          indicatorColor="primary"
          value={tabValueRef}
          onChange={handleTabChange}
        >
          {tabContents.map((tab) => (
            <Tab key={tab.label} label={tab.label} />
          ))}
        </Tabs>
      </Box>
      {tabContents.map((tab, i) => (
        <TabPanel key={i} value={tabValueRef} index={i}>
          {tab.label === 'Build' && <LoadBuild kitList={kitList} setKitList={setKitList} />}
          {tab.label === 'Kits' && (
            <LoadKits
              kitList={kitList}
              setKitList={setKitList}
              openSnackbar={openSnackbar}
              identity={identity}
              rackName={rackName}
              systemType={systemType}
            />
          )}
          {tab.label === 'Components' && (
            <LoadComponent
              componentsList={componentsList}
              setComponentsList={setComponentsList}
              openSnackbar={openSnackbar}
              identity={identity}
              rackName={rackName}
              systemType={systemType}
            />
          )}
        </TabPanel>
      ))}
    </Box>
  );
}
