/* eslint-disable arrow-body-style */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';

import { LoadTabGroup } from '../loading/LoadTabGroup';

LoadInfo.propTypes = {
  updateRack: PropTypes.func,
  rack: PropTypes.object,
  setLoadTabValue: PropTypes.func,
  loadTabValue: PropTypes.object
};

export default function LoadInfo({ rack, updateRack, loadTabValue, setLoadTabValue }) {
  const [kitList, setKitList] = useState(
    rack.kits
      ? rack.kits
      : {
          software: [],
          content: [],
          media: []
        }
  );
  const [componentsList, setComponentsList] = useState(
    rack.components
      ? rack.components
      : {
          software: [],
          content: [],
          onemedia: [],
          media: []
        }
  );

  useEffect(() => {
    // updates rack kit list when updated
    const rackCopy = { ...rack };
    rackCopy.kits = kitList;
    updateRack(rackCopy);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [kitList]);

  useEffect(() => {
    // updates rack components list when updated
    const rackCopy = { ...rack };
    rackCopy.components = componentsList;
    updateRack(rackCopy);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [componentsList]);

  return (
    <Box sx={{ width: '100%', padding: 4 }}>
      <LoadTabGroup
        rackName={rack.releaseName}
        systemType={rack.rack.systemType}
        kitList={kitList}
        setKitList={setKitList}
        componentsList={componentsList}
        setComponentsList={setComponentsList}
        tabValueRef={loadTabValue}
        setTabValueRef={setLoadTabValue}
      />
    </Box>
  );
}
