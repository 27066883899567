import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormControl, Autocomplete, TextField, FormHelperText } from '@material-ui/core';
import axios from 'axios';
import { getIDToken } from '../../authentication/login/amplify';

ProgramSelect.propTypes = {
  airline: PropTypes.string,
  setProgram: PropTypes.func
};
const defaultProgramHelperText = `The program you wish to select a build from.`;
const noProgramsExistsHelperText = `There are no programs returned for the currently selected airline.`;

export default function ProgramSelect({ airline, setProgram }) {
  const [programs, setPrograms] = useState([]);
  const [currentProgram, setCurrentProgram] = useState(null);
  const [programHelperText, setProgramHelperText] = useState(defaultProgramHelperText);
  const [programError, setProgramError] = useState(false);

  const fetchPrograms = async () => {
    const jwt = await getIDToken();
    if (jwt) {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API}/scmdb/programs/${airline}`,
        headers: { Authorization: `Bearer ${jwt}` }
      })
        .then((response) => {
          console.debug(`successfully fetched programs: ${JSON.stringify(response.data)}`);
          setPrograms(response.data);
          if (response.data.length === 0) {
            setProgramHelperText(noProgramsExistsHelperText);
            setProgramError(true);
          }
        })
        .catch(() => {});
    } else {
      console.error('JWT has expired, cannot add role to team');
    }
  };

  useEffect(() => {
    setProgram('');
    setPrograms([]);
    setCurrentProgram(null);
    setProgramHelperText(defaultProgramHelperText);
    setProgramError(false);

    if (airline !== '') {
      // Proceed to fetch all of the airlines stored
      fetchPrograms();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [airline]);

  return (
    <FormControl sx={{ marginTop: '10px', width: '100%' }}>
      <Autocomplete
        fullWidth
        options={programs}
        onChange={(_, value) => {
          setCurrentProgram(value);
          setProgram(value ? value.ID : '');
        }}
        disabled={programError}
        value={currentProgram}
        getOptionLabel={(option) => `${option.series_number} (${option.aircraft_type})`}
        renderInput={(params) => (
          <TextField {...params} label={`${airline.toUpperCase()} Programs`} />
        )}
      />
      <FormHelperText error={programError}>{programHelperText}</FormHelperText>
    </FormControl>
  );
}
