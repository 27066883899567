import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Paper,
  Skeleton
} from '@material-ui/core';
import BuildTableRow from './BuildTableRows';
import Scrollbar from '../../Scrollbar';
import useVrackApi from '../../../hooks/useVrackApi';

BuildSelect.propTypes = {
  buildseries: PropTypes.string,
  setBuildID: PropTypes.func,
  setBuildDetails: PropTypes.func,
  closeModal: PropTypes.func
};

BuildSelect.defaultProps = {
  buildseries: null
};

export default function BuildSelect({ buildseries, setBuildID, setBuildDetails, closeModal }) {
  const [builds, setBuilds] = useState([]);
  const [buildLoading, setBuildLoading] = useState(false);
  const { fetchData: fetchBuildsFromSeries } = useVrackApi(
    'get',
    `/scmdb/programs/${buildseries}/builds`
  );

  useEffect(() => {
    const fetchBuilds = async () => {
      if (buildseries !== '') {
        setBuildLoading(true);
        // Proceed to fetch all of the airlines stored
        console.debug(`Fetching available builds for ${buildseries}`);
        await fetchBuildsFromSeries()
          .then((res) => {
            console.debug(`successfully fetched builds: ${JSON.stringify(res)}`);
            // remove any builds that do not have a software kit specified
            const validBuilds = res.filter((build) => build.loadables.software.length > 0);
            setBuilds(validBuilds);
            setBuildLoading(false);
          })
          .catch(() => {
            setBuilds([]);
          });
      } else {
        // empty out builds table if build series was reset
      }
    };
    setBuilds([]);
    fetchBuilds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildseries]);

  const getTableBody = () => {
    // if build is not yet loaded, proceed to render skeleton data
    if (buildLoading) {
      return [0, 1, 2, 3].map((_, index) => (
        <TableRow key={index}>
          <TableCell component="th" scope="row">
            <Skeleton />
          </TableCell>
          <TableCell align="right">
            <Skeleton />
          </TableCell>
          <TableCell align="right">
            <Skeleton />
          </TableCell>
          <TableCell align="right">
            <Skeleton />
          </TableCell>
          <TableCell align="right">
            <Skeleton />
          </TableCell>
        </TableRow>
      ));
    }

    // if build is loaded and the total length of builds is 0, render the not found body
    if (!buildLoading && builds.length === 0) {
      return (
        <TableRow>
          <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
            <Paper>
              <Typography gutterBottom align="center" variant="subtitle1">
                No Builds Found
              </Typography>
            </Paper>
          </TableCell>
        </TableRow>
      );
    }

    // otherwise, assume build data exists and proceed to render table contents
    return builds.map((build, i) => (
      <BuildTableRow
        key={i}
        row={build}
        closeModal={closeModal}
        setBuildID={setBuildID}
        setBuildDetails={setBuildDetails}
      />
    ));
  };

  return (
    <Scrollbar sx={{ maxHeight: 500 }}>
      <TableContainer>
        <Table size="small" aria-label="collapsible table" data-testid="build-select-table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: 20 }} />
              <TableCell>Build ID</TableCell>
              <TableCell align="right">System</TableCell>
              <TableCell align="right">Build Description</TableCell>
              <TableCell align="right">&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ marginX: 0 }}>{getTableBody()}</TableBody>
        </Table>
      </TableContainer>
    </Scrollbar>
  );
}
