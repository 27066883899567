import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import useVrackApi from '../../../hooks/useVrackApi';

RackName.propTypes = {
  rackName: PropTypes.string,
  updateRackName: PropTypes.func,
  handleRackNameValidationChange: PropTypes.func
};

// the regex rule to validate the rack name. this ensures the name is all lowercase, starts & ends with alphanumeric, and contains no spaces
const rackNameMaxLength = 40;
const validRackName = new RegExp(`^(?![0-9]+$)(?!.*-$)(?!-)[a-z0-9-]{1,${rackNameMaxLength}}$`);
const rackNameInputHelperText = 'The name you wish to use for your VRack';
const rackNameInputSuccessHelperText = 'Rack name is valid';
const rackNameInputErrEmptyText = "Rack name can't be empty";
const rackNameTooLongErrHelperText = 'Rack name must contain no more than 40 characters';
const rackNameInputErrHelperText = `Rack name must contain only lowercase alphanumeric characters or '-', and start and end with an alphanumeric number.`;
const rackNameAlreadyExistsHelperText = `Rack with that name already exists`;

export default function RackName({ rackName, updateRackName, handleRackNameValidationChange }) {
  const [vrackNameHelperText, setVrackNameHelperText] = useState(rackNameInputHelperText);
  const [vrackNameError, setVrackNameError] = useState(false);
  const [typingTimer, setTypingTimer] = useState(null);
  const { fetchData: vrackApiValidateRackName } = useVrackApi('get', `/racks/name/${rackName}`);

  useEffect(() => {
    handleRackNameValidationChange(false);
    validateInputs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateInputs = () => {
    if (rackName !== '') {
      validateRackName(rackName);
    }
  };

  const validateRackName = (rackName) => {
    // checks if it meets length criteria
    if (validRackName.test(rackName)) {
      // succeeds regex check for kubernetes naming convention
      setVrackNameHelperText(rackNameInputSuccessHelperText);
      setVrackNameError(false);
      updateRackName(rackName);
      handleRackNameValidationChange(true);
    } else {
      // set err message if invalid
      if (rackName.length > rackNameMaxLength) {
        setVrackNameHelperText(rackNameTooLongErrHelperText);
      } else if (rackName === '') {
        setVrackNameHelperText(rackNameInputErrEmptyText);
      } else {
        setVrackNameHelperText(rackNameInputErrHelperText);
      }
      setVrackNameError(true);
    }
  };

  const handleRacknameChange = (event) => {
    // Set button to disabled until validation is completed
    handleRackNameValidationChange(false);
    const rackName = event.target.value;

    clearTimeout(typingTimer);
    setTypingTimer(
      setTimeout(async () => {
        // Call checkRackExists then call remaining checks afterwards
        vrackApiValidateRackName({
          url: `/racks/name/${rackName}`
        })
          .then(() => {
            // Rack Exists
            setVrackNameHelperText(rackNameAlreadyExistsHelperText);
            setVrackNameError(true);
          })
          .catch(() => {
            validateRackName(rackName);
          });
      }, 500)
    );
  };

  return (
    <TextField
      inputProps={{ 'data-testid': 'rack-name' }}
      fullWidth
      required
      label="VRack Name"
      defaultValue={rackName}
      helperText={vrackNameHelperText}
      onChange={handleRacknameChange}
      error={vrackNameError}
    />
  );
}
