import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormControl, Autocomplete, TextField, FormHelperText } from '@material-ui/core';
import axios from 'axios';
import { getIDToken } from '../../authentication/login/amplify';

AirlineSelect.propTypes = {
  setAirline: PropTypes.func
};

export default function AirlineSelect({ setAirline }) {
  const [airlines, setAirlines] = useState([]);

  useEffect(() => {
    // Proceed to fetch all of the airlines stored
    const fetchAirlines = async () => {
      const jwt = await getIDToken();
      if (jwt) {
        axios({
          method: 'get',
          url: `${process.env.REACT_APP_API}/scmdb/airlines`,
          headers: { Authorization: `Bearer ${jwt}` }
        })
          .then((response) => {
            console.debug(`successfully fetched airlines: ${JSON.stringify(response.data)}`);
            response.data.sort((a, b) => a.name.localeCompare(b.name));
            setAirlines(response.data);
          })
          .catch(() => {});
      } else {
        console.error('JWT has expired, cannot add role to team');
      }
    };
    fetchAirlines();
  }, []);

  return (
    <FormControl sx={{ marginTop: '10px', width: '100%' }}>
      <Autocomplete
        fullWidth
        data-testid="airline-select"
        options={airlines}
        onChange={(_, value) => {
          if (value) {
            setAirline(value.icao);
          } else {
            setAirline('');
          }
        }}
        getOptionLabel={(option) => `${option.name} (${option.icao})`}
        renderInput={(params) => <TextField {...params} label="Airlines" />}
      />
      <FormHelperText>The airline you wish to select a program from.</FormHelperText>
    </FormControl>
  );
}
