import PropTypes from 'prop-types';

import { Autocomplete, TextField } from '@material-ui/core';
import { useState } from 'react';

RackTDsDropDown.propTypes = {
  flightDataList: PropTypes.array,
  setFlightDataList: PropTypes.func,
  aircraftDataList: PropTypes.array
};

export default function RackTDsDropDown({ flightDataList, setFlightDataList, aircraftDataList }) {
  const [searchAircraftDataKey, setSearchAircraftDataKey] = useState(null);

  const addNewFlightDataToList = (event, newFlightData) => {
    event.stopPropagation();
    const updatedFlightDataList = [...flightDataList];
    updatedFlightDataList.push({ key: newFlightData.key, value: '', new: true, checked: true });
    setFlightDataList(updatedFlightDataList);
  };

  return (
    <Autocomplete
      size="small"
      key={searchAircraftDataKey}
      onChange={(event, newValue) => {
        addNewFlightDataToList(event, newValue);
        // clears the autocomplete value by setting a new key
        setSearchAircraftDataKey(Date.now());
      }}
      options={aircraftDataList}
      getOptionLabel={(option) => option.value || ''}
      renderInput={(params) => <TextField {...params} label="Search for Aircraft Data ..." />}
      data-testid="drop-down"
    />
  );
}
