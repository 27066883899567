import {
  IconButton,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core';
import PropTypes from 'prop-types';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const componentTypes = ['software', 'content', 'media', 'metadata'];
const styles = {
  componentsTable: {
    marginY: 2
  },
  tableNameColumn: { maxWidth: 180, overflow: 'auto', whiteSpace: 'nowrap' },
  tableNameHeader: { minWidth: 200 }
};

LoadComponentTable.propTypes = {
  componentsList: PropTypes.object,
  setComponentsList: PropTypes.func,
  disableControls: PropTypes.bool
};

export default function LoadComponentTable({ componentsList, setComponentsList, disableControls }) {
  const removeComponent = (components, componentRow) => {
    const newComponents = { ...components };
    // find the index of the component to remove depending on their pn / filename
    const foundComponent = (comp) =>
      (componentRow.partnumber && componentRow.partnumber === comp.partnumber) ||
      (componentRow.filename && componentRow.filename === comp.filename);
    const removeIndex = components[componentRow.type].findIndex(foundComponent);
    newComponents[componentRow.type].splice(removeIndex, 1);
    // update components list
    setComponentsList(newComponents);
  };

  const getComponentTableRows = () => {
    const rows = [];
    componentTypes.forEach((compType) => {
      if (componentsList[compType]) {
        componentsList[compType].forEach((c) => {
          const newRow = { ...c };
          newRow.type = compType;
          rows.push(newRow);
        });
      }
    });
    return rows;
  };

  const getComponentIdentifier = (comp) => {
    switch (comp.source) {
      case 'aws':
        return comp.filename;
      case 'scmdb':
        return comp.partnumber;
      case 'mma':
        return `${comp.mediainfo.airline}-${comp.mediainfo.profile}-${comp.mediainfo.cycle}`.toLowerCase();
      default:
        return '';
    }
  };

  // checks whether a component was added to the list
  const isComponentAdded = () => {
    let foundComponent = false;
    componentTypes.forEach((compType) => {
      if (componentsList[compType]) {
        if (componentsList[compType].length > 0) {
          foundComponent = true;
        }
      }
    });
    return foundComponent;
  };

  return (
    <TableContainer
      data-testid="load-component-table"
      component={Paper}
      sx={styles.componentsTable}
    >
      <Table aria-label="load-component-table" size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={styles.tableNameHeader}>Identifier</TableCell>
            <TableCell>Version</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Source</TableCell>
            <TableCell>System</TableCell>
            {!disableControls ? <TableCell align="right">Controls</TableCell> : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {isComponentAdded() ? (
            getComponentTableRows().map((row) => (
              <TableRow key={row.partnumber ? row.partnumber : row.filename}>
                <TableCell sx={styles.tableNameColumn}>{getComponentIdentifier(row)}</TableCell>
                <TableCell>{row.version ? row.version : 'n/a'}</TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>{row.source === 'aws' ? 'team-loadable' : row.source}</TableCell>
                <TableCell>{row.system.toUpperCase()}</TableCell>
                {!disableControls ? (
                  <TableCell align="right">
                    <IconButton
                      edge="end"
                      aria-label="comments"
                      onClick={() => {
                        removeComponent(componentsList, row);
                      }}
                    >
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                  </TableCell>
                ) : null}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell align="center" colSpan={12} sx={{ py: 2 }}>
                <Paper>
                  <Typography gutterBottom align="center" variant="subtitle2">
                    No Components
                  </Typography>
                </Paper>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
