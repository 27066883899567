import { useState } from 'react';

import PropTypes from 'prop-types';
import {
  TableRow,
  TableCell,
  Collapse,
  IconButton,
  Box,
  Table,
  TableBody,
  TableHead,
  Button
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import palette from '../../../theme/palette';

BuildTableRow.propTypes = {
  row: PropTypes.shape({
    ID: PropTypes.string,
    build_reason: PropTypes.string,
    systems: PropTypes.string,
    loadables: PropTypes.object
  }),
  setBuildID: PropTypes.func,
  setBuildDetails: PropTypes.func,
  closeModal: PropTypes.func
};

export default function BuildTableRow({ row, setBuildID, setBuildDetails, closeModal }) {
  const [open, setOpen] = useState(false);

  const handleSelectBuild = (buildID, buildDetails) => {
    setBuildID(buildID);
    setBuildDetails(buildDetails);
    closeModal();
  };

  return (
    <>
      <TableRow hover onClick={() => setOpen(!open)}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.ID}</TableCell>
        <TableCell align="right">{row.systems}</TableCell>
        <TableCell align="right">{row.build_reason}</TableCell>
        <TableCell align="right">
          <Button variant="contained" size="small" onClick={() => handleSelectBuild(row.ID, row)}>
            Select
          </Button>
        </TableCell>
      </TableRow>
      <TableRow sx={{ background: palette.grey[100] }}>
        <TableCell sx={{ paddingY: 0 }} colSpan={6}>
          <Collapse in={open} unmountOnExit>
            <Box>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Kit Name</TableCell>
                    <TableCell>Part Number</TableCell>
                    <TableCell align="right">Version</TableCell>
                    <TableCell align="right">System</TableCell>
                    <TableCell align="right" sx={{ paddingRight: 0 }}>
                      SRR
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.loadables.software.map((kit, i) => (
                    <TableRow key={i}>
                      <TableCell>{kit.name}</TableCell>
                      <TableCell>{kit.partnumber}</TableCell>
                      <TableCell align="right">{kit.version}</TableCell>
                      <TableCell align="right">{kit.systems}</TableCell>
                      <TableCell align="right" sx={{ paddingRight: 0 }}>
                        {kit.srr}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
